<template>
  <div ref="ToolsPicker">
    <QSelect
      v-model="tools"
      :options="filteredOptions"
      :readonly="readonly"
      :label="$t({ id: 'component.forms.offerForm.tools.select' })"
      options-selected-class="bg-green-2"
      bg-color="white"
      dropdown-icon="uil:angle-down"
      input-debounce="350"
      :dense="dense"
      map-options
      emit-value
      multiple
      outlined
      use-input
      @filter="filterFn"
      @update:model-value="stepResult"
    >
      <template #selected-item />
      <template #option="scope">
        <QItem v-bind="scope.itemProps" class="bg-grey-1">
          <QItemSection avatar>
            <img class="br-8" :src="cdnImg(`assets/attributes/tools/${scope.opt.value}.png`, { height: 42 })" loading="lazy" style="background-color:white; height:42px">
          </QItemSection>
          <QItemSection>
            <!-- eslint-disable vue/no-v-html -->
            <QItemLabel v-html="scope.opt.label" />
            <!-- eslint-enable vue/no-v-html -->
          </QItemSection>
          <QItemSection v-if="tools.includes(scope.opt.value)" side>
            <QIcon name="uil:check-circle" color="positive" />
          </QItemSection>
        </QItem>
      </template>
      <template #no-option>
        <QItem>
          <QItemSection avatar>
            <QIcon name="uil:smiley-sad" />
          </QItemSection>
          <AppContent tag="QItemSection" path="component.forms.offerForm.tools.no_results" class="text-grey-1" />
        </QItem>
      </template>
    </QSelect>
    <div class="full-width">
      <QChip
        v-for="(t, i) of $_.compact($_.concat(tools, toolsCustom))"
        :key="i"
        :label="methodLabel(t.replaceAll('_', ' '))"
        text-color="white"
        color="secondary"
        outline
        square
        removable
        @remove="toggleTool(t)"
      />
    </div>
    <HCBigButton
      v-if="!$_.get(step, 'noCustom', false)"
      color="secondary"
      class="q-mt-md"
      label="Ajouter un outil personnalisé"
      @click="showInput = !showInput"
    />
    <QInput
      v-if="showInput"
      v-model="customInput"
      :readonly="readonly"
      :label="$t({ id: 'component.chips_picker.add' })"
      class="q-mt-md full-width"
      bg-color="white"
      type="text"
      outlined
    >
      <template v-if="customInput && !readonly" #append>
        <QIcon name="uil:plus" class="cursor-pointer" @click="toggleTool(customInput.replaceAll(' ', '_'))" />
      </template>
    </QInput>
  </div>
</template>

<script>
export default {
  props: {
    formObject: {
      type: Object,
      default: null,
    },
    step: {
      type: Object,
      default: null,
    },
    readonly: {
      type: Boolean,
      default: false,
    },
    dense: {
      type: Boolean,
      default: false,
    },
  },
  emits: ['stepResult'],
  data () {
    return {
      tools: [],
      toolsCustom: [],
      customInput: null,
      showInput: false,
      filteredOptions: [],
    }
  },
  computed: {
    toolsOptions () { return this.getCustomAttributeValues('tools') },
    keyTools () { return this.$_.get(this.step, 'fields[0]', 'customAttributes.tools') },
    keyToolsCustom () { return this.$_.get(this.step, 'fields[1]', 'customAttributes.tools_custom') },
  },
  created () {
    this.$nextTick(() => {
      if (this.$_.has(this.formObject, this.keyTools)) this.tools = this.$_.get(this.formObject, this.keyTools)
      // Workaround since text CustomAttributes can't be array typed
      if (this.$_.has(this.formObject, this.keyToolsCustom)) this.toolsCustom = this.$_.get(this.formObject, this.keyToolsCustom, []).split(' ')
      this.filteredOptions = this.toolsOptions
      this.stepResult()
    })
  },
  methods: {
    stepResult () {
      const value = {}
      this.$_.set(value, this.keyTools, this.tools)
      this.$_.set(value, this.keyToolsCustom, this.toolsCustom.join(' '))
      this.$emit('stepResult', {
        canGoNext: (this.tools !== null && this.tools.length > 0) || (this.step && this.step.optional),
        value: value,
      })
    },
    filterFn (val, update) {
      if (val === '') {
        update(() => { this.filteredOptions = this.toolsOptions })
        return
      }
      update(() => {
        const needle = val.toLowerCase()
        this.filteredOptions = this.toolsOptions.filter(v => v.label.toLowerCase().indexOf(needle) > -1)
      })
    },
    toggleTool (tool) {
      if (this.toolsOptions.map(t => t.value).includes(tool)) {
        if (!this.tools) this.tools = [tool]
        else this.tools = this.$_.xor(this.tools, [tool])
      } else this.toolsCustom = this.$_.xor(this.toolsCustom, [tool])
      this.customInput = null
      this.stepResult()
    },
    methodLabel (t) {
      return this.translationExists(`customAttributes.tools.${t}`) ? this.$t({ id: `customAttributes.tools.${t}` }) : t
    },
  },
}
</script>
