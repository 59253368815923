<template>
  <div>
    <div v-for="(slider, index) of step.sliders" :key="index" class="flex column justify-start">
      <div class="text-grey-5 text-caption">
        <span class="text-grey-7 text-body1">
          {{ slider.reversed ? slider.max - inputSlide : inputSlide }}
        </span>
        {{ slider.label }}
      </div>
      <QSlider
        :model-value="slider.reversed ? slider.max - inputSlide : inputSlide"
        :min="slider.min"
        :max="slider.max"
        :color="slider.color"
        :readonly="readonly"
        @pan="setPan"
        @update:model-value="tempo"
        @change="val => { inputSlide = slider.reversed ? slider.max - val : val }"
      />
    </div>
  </div>
</template>

<script>
export default {
  props: {
    formObject: {
      type: Object,
      default: null,
    },
    step: {
      type: Object,
      default: null,
    },
    readonly: {
      type: Boolean,
      default: false,
    },
  },
  emits: ['stepResult'],
  data () {
    return {
      panning: false,
      inputSlide: 50
    }
  },
  created () {
    this.$nextTick(() => {
      if (!this.$_.get(this.formObject, this.step.field) && this.$_.get(this.step, 'copyFromObject')) {
        this.inputSlide = this.$_.get(this.step, `copyFromObject.${this.step.field}`, this.step.default)
      } else this.inputSlide = this.$_.get(this.formObject, this.step.field, this.step.default)
      this.stepResult()
    })
  },
  methods: {
    stepResult () {
      if (this.panning) return
      const value = {}
      this.$_.set(value, this.step.field, this.inputSlide)
      this.$emit('stepResult', {
        canGoNext: Number.isInteger(this.inputSlide) || this.$_.get(this.step, 'optional', false),
        value
      })
    },
    setPan (pan) { this.panning = pan === 'start' },
    tempo () { setTimeout(() => { this.stepResult() }, 300) } // Temporize lazy change
  }
}
</script>
