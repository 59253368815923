<template>
  <AccountCard ref="AccountFormCard" :title="title" :path="path" :icon="icon" :signal="savedSignal" :loading="loading">
    <template #header-right>
      <div class="row items-center justify-end">
        <HCButton v-if="showDelete" color="negative" icon="uil:times-circle" tooltip="prompt.delete_button" @click="deleteFields" />
        <HCButton v-if="showImport" color="dark" icon="uil:file-import" tooltip="component.cards.account_form_card.import_data_from" @click="importDialog = true" />
      </div>
      <QDialog v-if="showImport" v-model="importDialog">
        <AccountCard style="min-width:60%" path="component.cards.account_form_card.import_data_from" icon="uil:file-import" :signal="savedSignal">
          <QCardSection>
            <!-- Remove fields and vModel to keep steps only, care to the companyAttributeSelector -->
            <CompanyAttributeSelector v-model="attributesToCopy" :fields="steps" :steps="steps" @attributeSelect="attributeSelect" />
          </QCardSection>
          <QCardSection>
            <div class="flex justify-between">
              <HCButton v-close-popup color="grey" icon="uil:times" label="prompt.close_button" />
              <QBtn no-caps flat :label="$t({ id: 'prompt.copy_button' })" size="md" icon="uil:file-import" color="positive" @click="save({ attrs: clonedAttributes })" />
            </div>
          </QCardSection>
        </AccountCard>
      </QDialog>
    </template>

    <QCardSection v-if="!dataLoading" :class="`relative-postiion ${slotClass}`">
      <div class="row full-width">
        <div
          v-for="step of steps"
          :key="step.index"
          class="col-12 q-pa-xs"
        >
          <FormStepCard
            ref="linkedComponent"
            :step="step"
            :readonly="readonly"
            :form-object="modelValue"
            light
            @saveStep="saveStep($event, step)"
          />
        </div>
      </div>
      <slot />
      <div v-if="showImport && showImportHint && isOrganization()">
        <div class="q-mt-md">
          <QBanner class="q-pa-md bg-blue-1 text-center flex column">
            <QIcon size="md" color="primary" class="q-mb-md" name="uil:info-circle" />
            <AppContent path="component.cards.account_form_card.fill_by_import" />
            <AppContent path="component.cards.account_form_card.find_where" class="text-grey text-caption" />
            <QBtn flat class="q-mt-sm" no-caps color="dark" size="md" icon="uil:file-import" unelevated :label="$t({ id: 'component.cards.account_form_card.import' })" @click="importDialog = true" />
          </QBanner>
        </div>
      </div>
    </QCardSection>
    <QCardSection v-else>
      <HCLoading />
    </QCardSection>
  </AccountCard>
</template>

<script>
import AwsMixins from 'hc-core/mixins/aws.js'
import { useSavedSignal, stepResultExtractor } from 'hc-core/composables/misc.js'
import ValidationMixin from 'hc-core/mixins/validation'
import FormStepCard from 'hc-core/components/cards/form-step-card'
import CompanyAttributeSelector from 'hc-core/components/inputs/company-attribute-selector'

export default {
  components: { FormStepCard, CompanyAttributeSelector },
  mixins: [AwsMixins, ValidationMixin],
  props: {
    dispatch: {
      type: String,
      default: ''
    },
    icon: {
      type: String,
      default: 'uil:info-circle'
    },
    title: {
      type: String,
      default: ''
    },
    slotClass: {
      type: String,
      default: ''
    },
    modelValue: {
      type: Object,
      default: null
    },
    showImport: {
      type: Boolean,
      default: false
    },
    showDelete: {
      type: Boolean,
      default: false
    },
    showImportHint: {
      type: Boolean,
      default: false
    },
    fields: {
      type: Array,
      default: () => []
    },
    steps: {
      type: Array,
      default: () => []
    },
    path: {
      type: String,
      required: false, // Make it required once all transition is done
      default: null
    },
    readonly: {
      type: Boolean,
      default: false,
    },
    loading: { // use to show loading on the full card
      type: Boolean,
      default: false,
    },
  },
  emits: ['updated'],
  data () {
    return {
      importDialog: false,
      attributesToCopy: {},
      savedSignal: false,
      dataLoading: false,
      entityFields: [],
      clonedAttributes: {},
    }
  },
  computed: {
    common () { return this.$store.state.common },
  },
  methods: {
    useSavedSignal,
    attributeSelect (attrs) { this.clonedAttributes = attrs },

    // Allow saving multiple fields at once, by sending the attrs object
    // directly with the new values (to update or erase)
    async save ({ displayNotification = true, attrs = {} }) {
      try {
        if (attrs !== {}) {
          // TODO : make this super agnotic without these ifs,
          // but only the $store.dispatch(this.dispatch, { id: this.modelValue.id, attrs })
          if (this.dispatch === 'asset/updateCompany') {
            await this.$store.dispatch(this.dispatch, { asset: this.modelValue, attrs })
          }
          if (this.dispatch === 'user/update') {
            await this.$store.dispatch(this.dispatch, { id: this.modelValue.id, attrs })
          }
          if (displayNotification) this.useSavedSignal()
        }
      } catch (e) { this.useLogger(e) }
    },

    async saveStep (stepResult, step) {
      // Check if there are changes
      const compareTo = {}
      if (this.$_.get(step, 'fields', []).length > 1) { // Field is a superset of fields
        for (const f of step.fields) this.$_.set(compareTo, f, this.$_.get(this.modelValue, f))
      } else this.$_.set(compareTo, step.field, this.$_.get(this.modelValue, step.field))
      if (Object.keys(stepResultExtractor(compareTo, stepResult.stepValue)).length) {
        await this.save({ attrs: stepResult.stepValue })
      }
      this.$emit('updated')
    },

    deleteFields () {
      this.$q.dialog({
        title: this.$t({ id: 'component.cards.account_form_card.dialog.title' }),
        message: this.$t({ id: 'component.cards.account_form_card.dialog.message' }),
        cancel: { label: this.$t({ id: 'prompt.cancel_button' }), size: 'md', color: 'dark' },
        ok: { label: this.$t({ id: 'prompt.delete_button' }), size: 'md', color: 'negative' },
      }).onOk(async () => {
        try {
          const attrs = {}
          for (const step of this.steps) {
            for (const delKey of step.fields) this.$_.set(attrs, delKey, null)
          }
          await this.save({ attrs })
        } catch (e) { this.useLogger(e) }
      })
    },
  },
}
</script>
