<template>
  <HCButton
    v-if="path"
    color="positive"
    label="Aide"
    icon="uil:question-circle"
    tooltip="Aide"
    class="q-mx-sm float-right"
    @click="helpDialog = true"
  />
  <QDialog v-model="helpDialog" :maximized="$q.screen.lt.sm" @hide="helpDialog = false">
    <AccountCard icon="uil:question-circle" :title="`Aide${title ? ` - ${computedLabel(title)}` : ''}`" :style="styles">
      <template #header-right>
        <HCButton v-close-popup is-close />
      </template>
      <QCardSection>
        <AppContent :path="path" />
      </QCardSection>
    </AccountCard>
  </QDialog>
</template>

<script>
export default {
  props: {
    title: {
      type: String,
      default: null
    },
    path: {
      type: String,
      default: null
    },
    styles: {
      type: String,
      default: ''
    }
  },
  data () {
    return {
      helpDialog: false,
    }
  },
  methods: {
    computedLabel (label) {
      return label ? this.translationExists(label) ? this.$t({ id: label }) : label : false
    },
  }
}
</script>
