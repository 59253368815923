<template>
  <QInput
    :ref="`TextInput${$_.get(step, 'field') ? `.${$_.get(step, 'field')}` : ''}`"
    v-model="inputText"
    v-model.number="inputText"
    :readonly="readonly"
    class="br-8"
    :input-class="readonly ? 'text-grey-4' : ''"
    color="grey-5"
    bg-color="white"
    :label="computedLabel"
    :error="errorLabels.length > 0"
    :type="$_.get(step, 'type', 'text')"
    :mask="$_.get(step, 'mask', undefined)"
    :counter="$_.get(step, 'maxlength', $_.get(step, 'counter', null)) !== null"
    :maxlength="$_.get(step, 'maxlength', null)"
    :unmasked-value="$_.get(step, 'unmasked', null)"
    :error-message="errorLabels.length > 0 ? $t({ id: errorLabels[0]}) : ''"
    debounce="500"
    outlined
    bottom-slots
    @update:model-value="stepResult"
  />
  <!-- :hint="$_.get(step, 'hint', false) ? $t({ id: step.hint }) : undefined" -->
</template>

<script>
export default {
  props: {
    formObject: {
      type: Object,
      default: null,
    },
    step: {
      type: Object,
      default: null,
    },
    light: {
      type: Boolean,
      default: false,
    },
    readonly: {
      type: Boolean,
      default: false,
    },
  },
  emits: ['stepResult'],
  data () {
    return {
      inputText: null,
      errorLabels: []
    }
  },
  computed: {
    // TODO : consider always passing trn strings, so directly use $t in :label
    computedLabel () {
      const l = this.$_.get(this.step, 'label', this.$_.get(this.step, 'menuLabel', undefined))
      return l ? this.translationExists(l) ? this.$t({ id: l }) : l : false
    },
  },
  created () {
    this.$nextTick(() => {
      if (!this.$_.get(this.formObject, this.step.field) && this.$_.get(this.step, 'copyFromObject')) {
        this.inputText = this.$_.get(this.step, `copyFromObject.${this.step.field}`)
      } else this.inputText = this.$_.get(this.formObject, this.step.field)
      this.stepResult()
    })
  },
  mounted () {
    if (!this.light) this.$refs[`TextInput${this.$_.get(this.step, 'field') ? `.${this.$_.get(this.step, 'field')}` : ''}`].focus()
  },
  methods: {
    async stepResult () {
      const value = {}
      this.$_.set(value, this.step.field, this.inputText)
      let canGoNext = this.$_.get(this.inputText, 'length', 0) > 0
      this.errorLabels = []
      if (this.step.rules && this.$_.get(this.inputText, 'length', 0) > 0) { // Totally not sure about this
        for (const rule of this.step.rules) {
          const ruleResult = rule(this.inputText)
          canGoNext = canGoNext && ruleResult.fn
          if (!ruleResult.fn) this.errorLabels.push(ruleResult.errorLabel)
        }
      }
      if (this.$_.get(this.inputText, 'length', 0) > 0 && this.$_.get(this.step, 'type', 'text') === 'email' && this.$_.get(this.step, 'checkEmailAvailability', false)) {
        const isAvailable = await this.$store.dispatch('auth/checkAvailability', { username: this.inputText })
        if (!isAvailable) this.errorLabels.push('authentication.error.email_already_used')
        canGoNext = canGoNext && isAvailable
      }
      canGoNext = canGoNext || this.$_.get(this.step, 'optional', false)
      this.$emit('stepResult', { canGoNext, value })
    }
  },
}
</script>
