import { ref } from 'vue'

export function useIntegrations () {
  const integrations = ref([
    {
      key: 'digitalRecruiters',
      label: 'DigitalRecruiters',
      description: 'Publiez automatiquement vos offres depuis DigitalRecruiters vers HappyCab et son réseau de multidiffusion, et centralisez vos candidatures sur DigitalRecruiters.',
      image: 'platform/jobboards/digitalrecruiters.png',
      connectFn: () => {
        console.log('DigitalRecruiters')
      },
    },
    {
      key: 'jobAffinity',
      label: 'JobAffinity',
      description: 'Publiez automatiquement vos offres depuis JobAffinity vers HappyCab et son réseau de multidiffusion, et centralisez vos candidatures sur JobAffinity.',
      image: 'platform/jobboards/jobaffinity.png',
      connectFn: () => {
        console.log('jobAffinity')
      },
    },
    {
      key: 'quarksUp',
      label: 'QuarksUp',
      description: 'Publiez automatiquement vos offres depuis quarksUp vers HappyCab et son réseau de multidiffusion, et centralisez vos candidatures sur quarksUp.',
      image: 'platform/jobboards/quarksup.png',
      connectFn: () => {
        console.log('quarksUp')
      },
    }
  ])

  return {
    integrations
  }
}
