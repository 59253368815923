<template>
  <div>
    <!-- Have to warp input in div so mask is taken from step and not passed through prop -->
    <QInput
      ref="ISOInput"
      v-model="timestampInput"
      :mask="step.mask.replace(/[a-zA-Z]/g, '#')"
      fill-mask
      :readonly="readonly"
      :label="computedLabel"
      bg-color="white"
      outlined
      debounce="300"
      :rules="[i => !i.match('_') || '']"
      lazy-rules
      @update:model-value="stepResult('timestampInput')"
    >
      <template v-if="step.pickDate && !readonly" #prepend>
        <QIcon name="event" class="cursor-pointer">
          <QPopupProxy ref="popupPicker" cover transition-show="scale" transition-hide="scale">
            <QDate
              v-model="timestampDate"
              mask="YYYY-MM-DD"
              :navigation-min-year-month="`${new Date().getFullYear()-100}/${new Date().getMonth()+1}`"
              :navigation-max-year-month="`${new Date().getFullYear()+1}/${new Date().getMonth()+1}`"
              minimal
              emit-immediately
              default-view="Years"
              @update:model-value="closePicker"
            >
              <div class="row items-center justify-end">
                <QBtn v-close-popup label="Close" color="primary" flat />
              </div>
            </QDate>
          </QPopupProxy>
        </QIcon>
      </template>

      <template v-if="step.pickTime && !readonly" #append>
        <QIcon name="access_time" class="cursor-pointer">
          <QPopupProxy cover transition-show="scale" transition-hide="scale">
            <QTime v-model="timestampTime" mask="HH:mm:ss" format24h @update:model-value="stepResult('timestampTime')">
              <div class="row items-center justify-end">
                <QBtn v-close-popup label="Close" color="primary" flat />
              </div>
            </QTime>
          </QPopupProxy>
        </QIcon>
      </template>
    </QInput>
  </div>
</template>

<script>
import { isoToMask, maskToIso } from 'hc-core/composables/time.js'
export default {
  props: {
    formObject: {
      type: Object,
      default: null,
    },
    step: {
      type: Object,
      default: null,
    },
    readonly: {
      type: Boolean,
      default: false,
    },
  },
  emits: ['stepResult'],
  data () {
    return {
      isoMask: 'YYYY-MM-DDTHH:mm:ss.000Z',
      timestampInput: '',
      timestampDate: '',
      timestampTime: '',
    }
  },
  computed: {
    // TODO : consider always passing trn strings, so directly use $t in :label
    computedLabel () {
      const l = this.$_.get(this.step, 'label', false)
      return l ? this.translationExists(l) ? this.$t({ id: l }) : l : false
    },
  },
  watch: {
    timestampInput: {
      deep: true,
      immediate: true,
      handler: function (val, oldVal) {
        if (val.match(/[a-zA-SU-Z]/g) && !oldVal.match(/[a-zA-SU-Z]/g)) {
          this.timestampInput = oldVal
        }
      }
    }
  },
  created () {
    this.$nextTick(() => {
      let str = new Date().toISOString()
      if (!this.$_.get(this.formObject, this.step.field) && this.$_.get(this.step, 'copyFromObject')) {
        str = this.$_.get(this.step, `copyFromObject.${this.step.field}`)
      } else str = this.$_.get(this.formObject, this.step.field, undefined)
      if (str) {
        const d = new Date(str)
        this.timestampDate = `${d.getFullYear()}-${d.getMonth() + 1}-${d.getDate()}`
        this.timestampTime = `${d.getHours()}:${d.getMinutes()}:${d.getSeconds()}`
        this.timestampInput = isoToMask(str, this.step.mask)
      }
    })
  },
  mounted () {
    this.$refs.ISOInput.focus()
  },
  methods: {
    closePicker (val, reason, details) {
      // TODO : check for french masks
      if (this.$refs.popupPicker && ((this.step.mask === 'YYYY' && reason === 'year') || (this.step.mask === 'YYYY-MM' && reason === 'month') || (this.step.mask === 'YYYY-MM-DD' && reason === 'add-day'))) {
        this.$refs.popupPicker.hide()
        this.stepResult('timestampDate')
      }
    },
    stepResult (overridingKey) {
      const value = {}
      try {
        let t = this.isoMask
        if (overridingKey === 'timestampInput') {
          if (this.timestampInput.match(/[a-zA-SU-Z]/g)) return
          t = maskToIso(this.timestampInput, this.step.mask)
        } else if (overridingKey === 'timestampDate') {
          t = t.replace('YYYY-MM-DD', this.timestampDate)
          this.timestampInput = this.timestampDate.substring(this.isoMask.indexOf(this.step.mask), this.step.mask.length)
        } else if (overridingKey === 'timestampTime') {
          t = t.replace('HH:mm:ss', this.timestampTime)
          this.timestampInput = this.timestampTime.substring(this.isoMask.indexOf(this.step.mask), this.step.mask.length)
        }
        // We already have an iso from above
        t = t.replace(/[a-zA-SU-Z]{2,}/g, '01')
        this.$_.set(value, this.step.field, t.match('_') ? undefined : t)
      } catch (e) {
        console.log(e)
        this.$_.set(value, this.step.field, undefined)
      }
      this.$emit('stepResult', {
        canGoNext: this.$_.has(value, this.step.field, undefined) || this.$_.get(this.step, 'optional', false),
        value: value,
      })
    },
  }
}
</script>
