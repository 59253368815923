<template>
  <div ref="FormStepCard">
    <!-- entity and field props are passed in for ImageCropperUploader component -->
    <template v-if="step && ready && light">
      <component
        :is="step.component"
        :step="step"
        :form-object="formObject"
        :entity="formObject"
        :readonly="readonly"
        :field="step"
        light
        :class="{'hc-focused': checkFocus(step.focusId)}"
        @stepResult="stepResult($event)"
      />
      <FocusProxy v-if="checkFocus(step.focusId)" path="asset.progression.company.alert" :name="step.focusId" />
    </template>
    <QCard v-else-if="step && ready">
      <QCardSection class="flex items-center justify-start bg-grey-1 text-body1-bold text-primary no-wrap">
        <div>{{ step.index+1 }}</div>
        <QIcon name="uil:angle-right" class="q-mx-xs" />
        <div class="text-dark">
          {{ step.label }}
        </div>
      </QCardSection>
      <QCardSection style="max-height:calc(50vh); overflow-y: auto;">
        <div v-if="step.hint" class="text-caption text-grey-4 q-mb-md">
          {{ step.hint }}
        </div>
        <component
          :is="step.component"
          :step="step"
          :form-object="formObject"
          @stepResult="stepResult($event)"
          @goToStep="$emit('goToStep', $event)"
        />
      </QCardSection>
    </QCard>
    <div v-if="!light" class="q-mt-xl flex full-width justify-center">
      <HCBigButton
        v-show="step.index !== 0"
        color="accent"
        class="q-mr-sm"
        label="prompt.previous_button"
        @click="$emit('saveStep', { stepIncrement: -1, stepValue: {} })"
      />
      <HCBigButton
        color="primary"
        class="q-ml-sm"
        :disable="!canGoNext"
        label="prompt.next_button"
        @click="$emit('saveStep', { stepIncrement: 1, stepValue: stepValue })"
      />
    </div>
  </div>
</template>

<script>
import { defineAsyncComponent } from 'vue'

const TextInput = defineAsyncComponent(() => import('hc-core/components/forms/text-input'))
const DateSelect = defineAsyncComponent(() => import('hc-core/components/forms/date-select'))
const ChipsPicker = defineAsyncComponent(() => import('hc-core/components/forms/chips-picker'))
const ToolsPicker = defineAsyncComponent(() => import('hc-core/components/forms/tools-picker'))
const SelectInput = defineAsyncComponent(() => import('hc-core/components/forms/select-input'))
const SliderInput = defineAsyncComponent(() => import('hc-core/components/forms/slider-input'))
const CheckboxInput = defineAsyncComponent(() => import('hc-core/components/forms/checkbox-input'))
const AdvantagesInput = defineAsyncComponent(() => import('hc-core/components/forms/advantages-input'))
const ImageCropperUploader = defineAsyncComponent(() => import('hc-core/components/files/image-cropper-uploader'))
const FocusProxy = defineAsyncComponent(() => import('hc-core/components/common/focus-proxy'))
const OfferLocation = defineAsyncComponent(() => import('hc-core/components/forms/offer-location'))
const OfferDate = defineAsyncComponent(() => import('hc-core/components/forms/offer-date'))
const OfferWage = defineAsyncComponent(() => import('hc-core/components/forms/offer-wage'))
const OfferDescription = defineAsyncComponent(() => import('hc-core/components/forms/offer-description'))
const OfferReview = defineAsyncComponent(() => import('hc-core/components/forms/offer-review'))
const MultipleItems = defineAsyncComponent(() => import('hc-core/components/forms/multiple-items'))
const LocationsInput = defineAsyncComponent(() => import('hc-core/components/forms/locations-input'))
const HeadcountInput = defineAsyncComponent(() => import('hc-core/components/forms/headcount-input'))
const PhoneInput = defineAsyncComponent(() => import('hc-core/components/forms/phone-input'))

export default {
  components: {
    TextInput,
    DateSelect,
    ChipsPicker,
    ToolsPicker,
    SelectInput,
    SliderInput,
    CheckboxInput,
    AdvantagesInput,
    ImageCropperUploader,
    FocusProxy,
    OfferLocation,
    OfferDate,
    OfferWage,
    OfferDescription,
    OfferReview,
    MultipleItems,
    LocationsInput,
    HeadcountInput,
    PhoneInput,
  },
  props: {
    formObject: {
      type: Object,
      default: null,
    },
    step: {
      type: Object,
      default: null,
    },
    light: {
      type: Boolean,
      default: false
    },
    readonly: {
      type: Boolean,
      default: false,
    },
  },
  emits: ['goToStep', 'saveStep'],
  data () {
    return {
      canGoNext: false,
      stepValue: null,
      ready: true,
    }
  },
  watch: {
    step: {
      deep: true,
      immediate: true,
      handler: function (val, oldVal) {
        this.ready = false
        this.$nextTick(() => {
          this.ready = true
        })
      }
    },
  },
  methods: {
    checkFocus (item) { return item && this.$store.state.common.focused === item },
    forceSave () { this.$emit('saveStep', { stepIncrement: 1, stepValue: this.stepValue }) },
    stepResult (event) {
      this.canGoNext = event.canGoNext
      this.stepValue = event.value
      if (this.light && this.canGoNext) this.forceSave()
    }
  }
}
</script>
