export const transitions = [
  {
    color: 'blue-2',
    accentColor: 'blue-4',
    slug: 'new',
    transitionName: null,
    label: 'pages.application.status.new',
  },
  {
    color: 'grey-2',
    accentColor: 'grey-4',
    slug: 'restricted',
    transitionName: null,
    label: 'pages.application.status.restricted',
  },
  {
    color: 'gold-2',
    accentColor: 'gold-4',
    slug: 'pending',
    transitionName: 'postpone',
    label: 'pages.application.status.pending',
  },
  {
    color: 'red-2',
    accentColor: 'red-4',
    slug: 'refused',
    transitionName: 'refuse',
    label: 'pages.application.status.refused',
  },
  {
    color: 'purple-1',
    accentColor: 'purple-4',
    slug: 'meeting',
    transitionName: 'meet',
    label: 'pages.application.status.meeting',
  },
  {
    color: 'green-2',
    accentColor: 'green-4',
    slug: 'recruited',
    transitionName: 'recruit',
    label: 'pages.application.status.recruited',
  },
]

export async function updateTransactionStatus ({ transaction, trsName }) {
  try {
    this.loading = true
    if (trsName === null) {
      this.notifyError('notification.not_authorized')
      return
    }

    await this.$store.dispatch('asset/createTransactionTransition', {
      transaction,
      transitionName: trsName,
    })

    this.$emit('hide')
    this.$emit('refresh')
    this.notifySuccess(this.$t({ id: 'notification.status_updated' }, { transitionName: this.$t({ id: `pages.application.transition.${trsName}` }) }))
  } catch (e) {
    this.useLogger(e)
  } finally {
    this.loading = false
  }
}
