<template>
  <div v-if="companies" ref="CompanyAttributeSelector">
    <div>
      <QSelect
        v-model="selectedImplantation"
        :options="companies"
        outlined
        :disable="disable"
        label="Sélectionnez une implantation"
        option-label="name"
        bg-color="white"
        @input="defaultCheckAttributes"
      />
    </div>
    <div>
      <QList separator bordered>
        <template v-for="step of steps" :key="step.index">
          <QExpansionItem dense expand-separator clickable :disable="!$_.has(selectedImplantation, step.field) || $_.get(selectedImplantation, step.field) === null || disable" class="hideSideSection">
            <template #header>
              <div class="full-width flex justify-between items-center">
                <div class="flex items-center">
                  <QCheckbox
                    v-model="keysToCopy"
                    :val="step.field"
                    :disable="disable || !selectedImplantation || !$_.get(selectedImplantation, step.field)"
                    @update:model-value="emitAttrs"
                  />
                  <div class="q-mr-sm text-weight-bold">
                    {{ step.label }}
                  </div>
                </div>
                <div v-if="selectedImplantation && !$_.has(selectedImplantation, step.field) || $_.get(selectedImplantation, step.field) === null" class="text-caption">
                  Non renseigné
                </div>
              </div>
            </template>
            <QCardSection v-if="step.type === 'boolean'">
              {{ step.displayLabel[String($_.get(selectedImplantation, step.field))] }}
            </QCardSection>
            <QCardSection v-else-if="step.type === 'select'">
              {{ $_.get($_.find(step.options, { value: $_.get(selectedImplantation, step.field) }), 'label', $_.get(selectedImplantation, step.field)) }}
            </QCardSection>
            <QCardSection v-else-if="step.type === 'img'">
              <QImg :src="cdnImg($_.get(selectedImplantation, step.field), { width: 100 })" loading="lazy" :ratio="1" class="gt-sm q-mr-sm" width="100px" />
            </QCardSection>
            <QCardSection v-else-if="step.type === 'gallery'">
              <GridMediaManager
                type="gallery"
                :medias="$_.get(selectedImplantation, step.field)"
                readonly
              />
            </QCardSection>
            <QCardSection v-else-if="['string', 'textarea', 'number'].includes(typeof $_.get(selectedImplantation, step.field) || $_.get(step, 'type', false))">
              {{ $_.get(selectedImplantation, step.field) }}
            </QCardSection>
          </QExpansionItem>
        </template>

        <!-- <template v-for="attribute of fields">
          <QExpansionItem v-if="attribute.type !== 'text'" :key="attribute.id" v-ripple class="hideSideSection" :disable="!$_.get(selectedImplantation, attribute.field) || disable" dense expand-separator clickable>
            <template #header>
              <div class="full-width flex items-center">
                <div>
                  <QCheckbox
                    v-model="keysToCopy"
                    :val="attribute.field"
                    :disable="disable || !selectedImplantation || !$_.get(selectedImplantation, attribute.field)"
                    @update:model-value="emitAttrs"
                  />
                  <QCheckbox v-if="$_.get(selectedImplantation, attribute.field)" v-model="attributesToCopy" color="positive" :change="$emit('input', { attributes: attributesToCopy, company: selectedImplantation })" :val="attribute.id" />
                  <QCheckbox v-else v-model="attributesToCopy" color="grey" />
                </div>
                <div style="flex:1;" class="q-ml-md flex justify-between">
                  <div class="flex items-center">
                    <div class="q-mr-sm text-weight-bold">
                      {{ attribute.label }}
                    </div>
                    <QChip v-if="typeof $_.get(selectedImplantation, attribute.field) === 'object' && $_.get(selectedImplantation, attribute.field)" square color="primary" dense text-color="white">
                      {{ $_.get(selectedImplantation, attribute.field, []).length }}
                    </QChip>
                  </div>
                  <div v-if="selectedImplantation && !$_.get(selectedImplantation, attribute.field)" class="text-caption">
                    Non renseigné
                  </div>
                </div>
              </div>
            </template>
            <QSeparator />
            <QCard class="my-card">
              <QCardSection>
                <div v-if="attribute.type === 'img' && attribute.square">
                  <QImg :src="cdnImg($_.get(selectedImplantation, attribute.field), { width: 100 })" loading="lazy" :ratio="1" class="gt-sm q-mr-sm" width="100px" />
                  <AppAvatar :entity="selectedImplantation" class="gt-sm q-mr-sm" :size="attribute.size || '40px'" />
                </div>
                <div v-else-if="attribute.type === 'img'">
                  <QImg :src="cdnImg($_.get(selectedImplantation, attribute.field))" loading="lazy" class="gt-sm q-mr-sm" />
                </div>
                <div v-else-if="typeof $_.get(selectedImplantation, attribute.field) === 'object'" :class="attribute.class">
                  <div v-for="(item, index) of $_.get(selectedImplantation, attribute.field, [])" :key="attribute.id + '_' + index">
                    <div v-if="attribute.valuekey && attribute.typekey">
                      <b>{{ $t({ id: attribute.i18n+$_.get(item, attribute.typekey, '') }) }}</b>
                      &nbsp;&nbsp;
                      {{ $_.get(item, attribute.valuekey, '') }}
                    </div>
                    <div v-else-if="attribute.valuekey && attribute.emojikey" class="q-mt-sm flex q-gutter-sm">
                      <QImg width="1.6rem" :src="getEmojiImage($_.get(item, attribute.emojikey, ''))" loading="lazy" />
                      <div>
                        {{ $_.get(item, attribute.valuekey, '') }}
                      </div>
                    </div>
                    <div v-else-if="attribute.imgkey">
                      <QCard class="bd-2 bd-grey" bordered flat>
                        <div class="flex flex-center column">
                          <img width="115px" :src="cdnImg(attribute.imgkey + item + attribute.imgext, { width: 115, height: 63 })" loading="lazy">
                        </div>
                      </QCard>
                    </div>
                    <div v-else>
                      {{ $t({ id: attribute.i18n+item }) }}
                    </div>
                  </div>
                </div>
                <div v-else>
                  {{ $_.get(selectedImplantation, attribute.field) }}
                </div>
              </QCardSection>
            </QCard>
          </QExpansionItem>
          <QItem v-else :key="attribute.id" class="hideSideSection" :disable="!$_.get(selectedImplantation, attribute.field)">
            <QItemSection>
              <div class="full-width flex items-center">
                <div>
                  <QCheckbox
                    v-model="keysToCopy"
                    :val="attribute.field"
                    @update:model-value="emitAttrs"
                  />
                  <QCheckbox v-else v-model="attributesToCopy" color="grey" :disable="disable" />
                </div>
                <div class="q-ml-md flex items-center">
                  <div class="q-mr-sm">
                    <div class="text-weight-bold">
                      {{ attribute.label }}
                    </div>
                    <div v-if="$_.get(selectedImplantation, attribute.field)" class="text-caption">
                      {{ $_.get(selectedImplantation, attribute.field) }}
                    </div>
                  </div>
                  <QChip v-if="typeof $_.get(selectedImplantation, attribute.field) === 'object' && $_.get(selectedImplantation, attribute.field)" square color="primary" dense text-color="white">
                    {{ $_.get(selectedImplantation, attribute.field, []).length }}
                  </QChip>
                </div>
              </div>
            </QItemSection>
          </QItem>
        </template> -->
      </QList>
    </div>
  </div>
</template>

<script>
import GridMediaManager from 'hc-core/components/tools/grid-media-manager'

export default {
  components: { GridMediaManager },
  props: {
    fields: {
      type: Array,
      default: () => []
    },
    value: {
      type: Array,
      default: null
    },
    disable: {
      type: Boolean,
      default: false
    },
    steps: {
      type: Array,
      default: () => []
    },
  },
  emits: ['attributeSelect'],
  data () {
    return {
      attributesToCopy: [],
      selectedImplantation: null,

      keysToCopy: [],
    }
  },
  computed: {
    companies () { return this.$uElements('companies') }
  },
  mounted () {
    this.attributesToCopy = this.value
    this.defaultCheckAttributes()
  },
  methods: {
    defaultCheckAttributes () {
      this.attributesToCopy = []
      for (const attr of this.fields) {
        if (this.$_.get(this.selectedImplantation, attr.field)) this.attributesToCopy.push(attr.id)
      }
    },
    getEmojiImage (unicode) { return 'https://twemoji.maxcdn.com/2/72x72/' + unicode + '.png' },
    emitAttrs () {
      const attrs = {}
      for (const key of this.keysToCopy) {
        if (this.$_.get(this.selectedImplantation, key)) this.$_.set(attrs, key, this.$_.get(this.selectedImplantation, key))
      }
      this.$emit('attributeSelect', attrs)
    }
  }
}
</script>

<style lang="sass">
  .hideSideSection
    .q-item__section--side
      padding-right: 0
    .q-item, &.q-item
      padding-left: 8px
</style>
