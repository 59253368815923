<template>
  <div ref="OfferDescription" style="max-width:85vw; max-height:70vh">
    <Editor
      :max-chars="step.maxChars"
      min-height="15rem"
      :readonly="readonly"
      :initial-content="description"
      @setContent="setContent"
    />
  </div>
</template>

<script>
import Editor from 'hc-core/components/tools/editor'
import { stripTags } from 'hc-core/composables/misc.js'

export default {
  components: { Editor },
  props: {
    formObject: {
      type: Object,
      default: null,
    },
    step: {
      type: Object,
      default: null,
    },
    readonly: {
      type: Boolean,
      default: false,
    },
  },
  emits: ['stepResult'],
  data () {
    return {
      description: null,
    }
  },
  created () {
    this.$nextTick(() => {
      this.description = this.$_.replace(this.$_.get(this, 'formObject.description', ''), /<div>\s*(<br\s*\/>|\\r|\\n)\s*<\/div>/gi, '') // Cleaning description string
      this.stepResult()
    })
  },
  methods: {
    stepResult () {
      const value = {}
      this.$_.set(value, this.step.field, this.description)
      this.$emit('stepResult', {
        canGoNext: this.description !== null || this.$_.get(this.step, 'optional', false),
        value: value,
      })
    },
    setContent (evt) {
      evt = this.$_.replace(evt, /<div>\s*(<br\s*\/>|\\r|\\n)\s*<\/div>/gi, '') // Cleaning description string
      if (stripTags(evt).length > this.step.maxChars) evt = evt.substring(0, this.step.maxChars - 1)
      this.description = evt
      this.stepResult()
    },
  },
}
</script>
