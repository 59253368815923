<template>
  <div ref="TestimonialCard">
    <AppContent
      path="component.forms.testimonialForm.card.age"
      class="text-h6 text-weight-bold"
      :options="{ name: $_.get(asset, 'metadata.testimonial.firstname', 'Anonyme'), nb: computedAge }"
    />
    <div class="text-negative">
      {{ $_.get(asset, 'metadata.testimonial.role', '') }}
    </div>
    <AppContent
      v-if="computedSeniority"
      path="component.forms.testimonialForm.card.seniority"
      class="text-grey-7 text-caption"
      :options="{ seniority: computedSeniority }"
    />
    <QChatMessage
      v-if="$_.get(asset, 'comment', false)"
      :text="[`<b>${$t({ id: 'component.forms.testimonialForm.card.msg_apply' })}</b><br/>${$_.get(asset, 'comment', '')}`]"
      text-color="white"
      bg-color="primary"
      text-html
      size="12"
      sent
      class="br-4"
    />
    <QList class="q-mt-md">
      <template
        v-for="question of questions"
        :key="question"
      >
        <QItem
          v-if="$_.get(asset, `metadata.testimonial[${question}]`, false)"
          class="q-pa-none q-mb-sm"
        >
          <QItemSection>
            <div class="text-weight-bold">
              {{ $t({ id: `component.forms.testimonialForm.title.${question}` }) }}
            </div>
            <div>{{ $_.get(asset, `metadata.testimonial[${question}]`, '') }}</div>
          </QItemSection>
        </QItem>
      </template>
    </QList>
  </div>
</template>

<script>
export default {
  props: {
    asset: {
      type: Object,
      required: true
    }
  },
  data () {
    return {
      questions: ['comment', 'whatsCool', 'review', 'lunch', 'sport', 'sportTeam', 'childs', 'hobbies', 'favoriteTvshow', 'favoriteMusic', 'musicStyle', 'secretTalent', 'magicPower', 'nickname']
    }
  },
  computed: {
    computedAge () {
      if (!this.$_.get(this.asset, 'metadata.testimonial.birthYear', false)) return 'X'
      const fromYear = Number.isInteger(this.$_.get(this.asset, 'metadata.testimonial.birthYear')) ? new Date(this.$_.get(this.asset, 'metadata.testimonial.birthYear'), 1, 1) : new Date(this.$_.get(this.asset, 'metadata.testimonial.birthYear'))
      return new Date().getFullYear() - fromYear.getFullYear()
    },
    computedSeniority () {
      let str = ''
      if (this.$_.get(this.asset, 'metadata.testimonial.seniority', '').match(/\d{2}\/\d{4}/)) {
        const split = this.$_.get(this.asset, 'metadata.testimonial.seniority', '').split('/')
        const d = new Date(split[1], split[0] - 1, 1, 12, 0, 0)
        str = `${new Intl.DateTimeFormat('fr-FR', { month: 'long' }).format(d)} ${d.getFullYear()}`
      } else {
        if (this.$_.get(this.asset, 'metadata.testimonial.seniorityMonth', false)) {
          str += `${this.$_.get(this.asset, 'metadata.testimonial.seniorityMonth', '')} `
        }
        if (this.$_.get(this.asset, 'metadata.testimonial.seniorityYear', false)) {
          str += `${this.$_.get(this.asset, 'metadata.testimonial.seniorityYear', '')} `
        }
      }
      return str.length ? str : false
    }
  }
}
</script>
