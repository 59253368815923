<template>
  <div
    v-if="actions"
    class="flex items-center justify-end"
  >
    <template v-if="(forceExpand || (expanded && $q.screen.gt.xs)) && !$_.compact(actions.map(action => action.subs)).length">
      <HCButton
        v-for="(action, i) in cptActions"
        :key="i"
        :icon="action.icon"
        :class="action.cptClass"
        :color="action.color"
        :tooltip="action.label ? action.label : undefined"
        :to="action.to"
        :href="action.href"
        :target="action.target"
        @click="action.fn ? action.fn(element) : null"
      />
    </template>
    <QBtn
      v-else
      dense
      size="md"
      flat
      color="dark"
      icon="uil:ellipsis-h"
    >
      <QMenu
        dense
        content-class="no-box-shadow"
        flat
      >
        <QList
          bordered
          flat
          style="min-width: 100px"
        >
          <QItem
            v-for="(action, i) in cptActions"
            :key="i"
            :v-close-popup="!action.subs"
            clickable
            :class="action.cptClass"
            :to="action.to"
            :href="action.href"
            :target="action.target"
            @click="action.fn ? action.fn(element) : null"
          >
            <QItemSection
              class="flex flex-center"
              avatar
            >
              <QIcon
                :name="action.icon"
                :color="action.iconColor ?? undefined"
              />
            </QItemSection>
            <QItemSection>
              {{ computedLabel(action.label) }}
            </QItemSection>
            <QItemSection
              v-if="action.subs"
              side
            >
              <QIcon name="keyboard_arrow_right" />
            </QItemSection>

            <!-- Subs -->
            <QMenu
              v-if="action.subs"
              dense
              content-class="no-box-shadow"
              flat
              :anchor="`top ${subMenuPosition}`"
              :self="`top ${subMenuPosition === 'left' ? 'right' : 'left'}`"
            >
              <QList bordered flat style="min-width: 100px">
                <QItem
                  v-for="(subAction, i) in action.subs"
                  :key="i"
                  v-close-popup
                  clickable
                  :class="subAction.cptClass"
                  :to="subAction.to"
                  :href="subAction.href"
                  :target="subAction.target"
                  @click="subAction.fn ? subAction.fn(element) : null"
                >
                  <QItemSection
                    class="flex flex-center"
                    avatar
                  >
                    <QIcon
                      :name="subAction.icon"
                      :color="subAction.iconColor ?? undefined"
                    />
                  </QItemSection>
                  <QItemSection>
                    {{ computedLabel(subAction.label) }}
                  </QItemSection>
                </QItem>
              </QList>
            </QMenu>
          </QItem>
        </QList>
      </QMenu>
    </QBtn>
  </div>
</template>

<script>
import { arrayIfFilter } from 'hc-core/composables/misc.js'

export default {
  props: {
    actions: {
      type: Array,
      required: true
    },
    element: {
      type: Object,
      required: false,
      default: () => null
    },
    expanded: {
      type: Boolean,
      default: false
    },
    forceExpand: {
      type: Boolean,
      default: false
    },
    subMenuPosition: {
      type: String,
      default: 'right'
    }
  },
  computed: {
    cptActions () {
      return arrayIfFilter(this.$_.compact(this.actions.map((action) => this.actionMapping(action))))
    }
  },
  methods: {
    computedLabel (label) {
      return label ? this.translationExists(label) ? this.$t({ id: label }) : label : false
    },
    actionMapping (action) {
      action.icon = action.icon ?? 'uil:info-circle'
      action.label = this.computedLabel(action.label)
      action.to = this.$_.get(action, 'to', undefined)
      action.href = this.$_.get(action, 'href', undefined)
      action.target = action.href ? '_blank' : '_self'
      action.color = this.$_.get(action, 'color', 'black')
      // Named property different than 'class' so no recursive updates loop
      action.cptClass = `${action.class ?? ''} text-${this.$_.get(action, 'color', 'black')}`
      action.fn = this.$_.get(action, 'fn', undefined)

      // Subs
      if (action.subs) action.subs = arrayIfFilter(this.$_.compact(action.subs.map((sub) => this.actionMapping(sub))))
      return this.$_.get(action, 'if', true) ? action : null
    }
  }
}
</script>
