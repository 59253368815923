<template>
  <AccountCard ref="AccountTabsCard" :header-class="'q-pb-none q-pt-none'" :separator="false" :bordered="bordered" :loading="loading">
    <template #header-left>
      <div class="flex justify-between q-gutter-md">
        <QTabs
          v-if="tabs.length > 1"
          v-model="dataTab"
          class="text-grey gt-sm"
          active-color="dark"
          indicator-color="white"
          align="left"
        >
          <QTab v-for="(tab, key) in tabs" :key="key" :name="tab.value" no-caps style="height:50px;" class="flex">
            <template #default>
              <slot name="tab" :tab="tab">
                <div class="flex items-center q-gutter-x-sm">
                  <QIcon v-if="tab.icon" size="sm" :name="tab.icon" :color="shownTab === tab.value ? 'primary' : 'grey-5'" />
                  <div :class="['text-body1-bold', {'text-grey-5':shownTab !== tab.value}]">
                    {{ tab.label }}
                  </div>
                </div>
              </slot>
            </template>
          </QTab>
        </QTabs>
        <QSelect v-model="dataTab" style="flex:1; min-width:180px" class="lt-md q-pa-xs" map-options emit-value :options="tabs" :label="selectLabel" outlined />
      </div>
    </template>
    <template #header-right>
      <slot name="header-right" />
    </template>
    <QCardSection :class="['q-pa-none relative-position fit', {'full-height': !$_.get(footerActions, `[${shownTab}][0]`)}]">
      <slot />
    </QCardSection>
    <slot v-if="$_.get(footerActions, `[${shownTab}][0]`)" name="footer">
      <QCardActions class="items-center justify-between">
        <QBtn
          v-for="action in footerActions[shownTab]"
          :key="action.key"
          :icon-right="action.icon ? action.icon : null"
          :label="action.label ? action.label : null"
          :disable="action.disable"
          color="primary"
          class="text-caption"
          no-caps
          flat
          @click="$emit(action.method, action.methodParameter)"
        >
          <component :is="action.component" v-if="action.showComponent" />
        </QBtn>
      </QCardActions>
    </slot>
  </AccountCard>
</template>

<script>
export default {
  props: {
    shownTab: {
      type: String,
      default: null,
    },
    selectLabel: {
      type: String,
      default: 'Onglet',
    },
    tabs: {
      type: Array,
      default: () => [],
    },
    footerActions: {
      type: Object,
      default: null
    },
    bordered: {
      type: Boolean,
      default: true,
    },
    loading: { // use to show loading on the full card
      type: Boolean,
      default: false,
    },
  },
  emits: ['setShownTab'],
  data () {
    return {
      dataTab: null
    }
  },
  watch: {
    shownTab: {
      deep: true,
      immediate: true,
      handler: function (value) {
        this.dataTab = value
      }
    },
    dataTab: {
      deep: true,
      immediate: true,
      handler: function (value) {
        this.$emit('setShownTab', value)
      }
    },
  },
}
</script>
