export default {
  data () {
    return {
      jobboards: [
        { label: 'HappyCab', color: 'primary', value: 'happycab', cond: { platformData: { _analytics: { source: 'happycab' } } } }, // channels: ['spontaneous']
        { label: 'Indeed', color: 'facebook', value: 'indeed', cond: { platformData: { _analytics: { source: 'indeed' } } } },
        { label: 'Jobijoba', color: 'red-4', value: 'jobijoba', cond: { platformData: { _analytics: { source: 'jobijoba' } } } }, // channels: ['sponso']
        { label: 'Talent', color: 'gold-5', value: 'talent', cond: { platformData: { _analytics: { source: 'talent' } } } },
        { label: 'Xtramile', color: 'gold-4', value: 'xtramile', cond: { platformData: { _analytics: { source: 'xtramile' } } } }, // channels: ['monster', 'jooble']
        {
          label: 'Jobposting',
          color: 'purple-5',
          value: 'jobposting',
          channels: [
            { value: 'adzuna', label: 'Adzuna', color: 'green-7', cond: { platformData: { _analytics: { source: 'jobposting', channel: 'adzuna' } } } },
            { value: 'apec', label: 'Apec', color: 'indigo-10', cond: { platformData: { _analytics: { source: 'jobposting', channel: 'apec' } } } },
            { value: 'jobposting', label: 'JobPosting', color: 'purple-6', cond: { platformData: { _analytics: { source: 'jobposting', channel: 'jobposting' } } } },
            { value: 'linkedin', label: 'LinkedIn', color: 'indigo-13', cond: { platformData: { _analytics: { source: 'jobposting', channel: 'linkedin' } } } },
            { value: 'pmejob', label: 'PmeJob', color: 'light-green-4', cond: { platformData: { _analytics: { source: 'jobposting', channel: 'pmejob' } } } },
            { value: 'pole-emploi', label: 'France Travail', color: 'blue-3', cond: { platformData: { _analytics: { source: 'jobposting', channel: 'pole-emploi' } } } },
          ],
          cond: { platformData: { _analytics: { source: 'jobposting' } } }
        },
        { label: 'Hellowork', color: 'red-5', value: 'hellowork', cond: { platformData: { _analytics: { source: 'hellowork' } } } }, // channels: ['monster', 'jooble']
      ],

      // Display for clients
      statuses: [
        { label: 'Nouveau', color: 'blue-4', icon: 'uil:plus-circle', value: 'new', cond: { status: 'new' } },
        { label: 'A traiter', color: 'gold-4', icon: 'uil:clock', value: 'pending', cond: { status: 'pending' } },
        { label: 'Refusé', color: 'red-4', icon: 'uil:times-circle', value: 'refused', cond: { status: 'refused' } },
        { label: 'Entretien', color: 'purple-4', icon: 'uil:comment-lines', value: 'meeting', cond: { status: 'meeting' } },
        { label: 'Recruté', color: 'green-4', icon: 'uil:check-circle', value: 'recruited', cond: { status: 'recruited' } },
      ],

      // Displayed for applicants
      applicantStatuses: [
        { label: 'Reçue', color: 'blue-4', icon: 'uil:plus-circle', value: ['new', 'restricted'] },
        { label: 'En cours', color: 'gold-4', icon: 'uil:clock', value: ['pending'] },
        { label: 'Refusé', color: 'red-4', icon: 'uil:times-circle', value: ['refused'] },
        { label: 'Entretien', color: 'purple-4', icon: 'uil:comment-lines', value: ['meeting'] },
        { label: 'Embauché', color: 'green-4', icon: 'uil:check-circle', value: ['recruited'] },
      ],

      // managerStatuses
      managerStatuses: [
        { label: 'Nouveau', color: 'blue-4', icon: 'uil:plus-circle', value: 'new', cond: { status: 'new' } },
        { label: 'A traiter', color: 'gold-4', icon: 'uil:clock', value: 'pending', cond: { status: 'pending' } },
        { label: 'Refusé', color: 'red-4', icon: 'uil:times-circle', value: 'refused', cond: { status: 'refused' } },
        { label: 'Entretien', color: 'purple-4', icon: 'uil:comment-lines', value: 'meeting', cond: { status: 'meeting' } },
        { label: 'Recruté', color: 'green-4', icon: 'uil:check-circle', value: 'recruited', cond: { status: 'recruited' } },
        { label: 'Restreinte', color: 'grey-4', icon: 'uil:file-alt', value: 'restricted', cond: { status: 'restricted' } },
        { label: 'Brouillon', color: 'grey-4', icon: 'uil:file-alt', value: 'draft', cond: { status: 'draft' } },
      ],
    }
  },
}
