<template>
  <div>
    <QCardSection>
      <div class="row q-col-gutter-md">
        <div v-for="(address, index) of inputLocations" :key="index" class="col-12">
          <QCard class="q-pa-md bg-grey-1" flat>
            <div class="flex justify-between items-center">
              <div class="flex q-gutter-md">
                <div class="flex column">
                  <div>{{ $_.get(address, 'street', '') }}</div>
                  <div>
                    {{ $_.get(address, 'postcode', '') }}
                    {{ $_.get(address, 'city', '') }}
                    {{ $_.get(address, 'country', '') }}
                  </div>
                </div>
              </div>
              <div class="flex column">
                <HCButton v-if="isApplicant() || isEditorOrAboveInOrg()" icon="uil:pen" tooltip="pages.addresses.edit" @click="editMode = address.id; selectedAddress = address; showDialog = true" />
                <HCButton v-if="inputLocations.length > 1" icon="uil:times-circle" color="negative" tooltip="pages.addresses.delete" @click="deleteAddress(address)" />
              </div>
            </div>
          </QCard>
        </div>
      </div>
      <div class="row justify-center items-center q-mt-md">
        <HCBigButton :disable="!isApplicant() && !isEditorOrAboveInOrg()" color="positive" :label="step.labelAdd ?? 'pages.addresses.add'" @click="editMode = false; showDialog = true" />
      </div>
    </QCardSection>

    <QDialog v-model="showDialog" @hide="showDialog = false">
      <AccountCard icon="uil:map-marker-plus" :path="editMode ? 'pages.addresses.edit' : 'pages.addresses.add'">
        <template #header-right>
          <HCButton v-close-popup is-close />
        </template>
        <QCardSection>
          <PlacesAutocomplete :value="selectedAddress" :style="'max-width:100%'" :dense="false" :filled="false" class="q-mb-md" placeholder="Adresse" add-insee-code @selectPlace="selectedAddress = $event" />
          <div class="text-center">
            <HCBigButton :loading="loading" color="positive" :label="`prompt.${editMode ? 'edit_button' : 'generic_add_button'}`" @click="handleAddress({ deleting: false })" />
          </div>
        </QCardSection>
      </AccountCard>
    </QDialog>
  </div>
</template>

<script>
import PlacesAutocomplete from 'hc-core/components/inputs/places-autocomplete'

export default {
  components: { PlacesAutocomplete },
  props: {
    formObject: {
      type: Object,
      default: null,
    },
    step: {
      type: Object,
      default: null,
    },
    readonly: {
      type: Boolean,
      default: false,
    },
  },
  emits: ['stepResult'],
  data () {
    return {
      loading: false,
      inputLocations: [],
      showDialog: false,
      selectedAddress: null,
      editMode: false,
      editedIndex: null,
      displayError: false,
    }
  },
  watch: {
    inputLocations: function (val) { this.stepResult() }
  },
  created () {
    this.$nextTick(() => {
      if (!this.$_.get(this.formObject, this.step.field) && this.$_.get(this.step, 'copyFromObject')) {
        this.inputLocations = this.$_.cloneDeep(this.$_.get(this.step, `copyFromObject.${this.step.field}`, []))
      } else this.inputLocations = this.$_.cloneDeep(this.$_.get(this.formObject, this.step.field, []))
      this.stepResult()
    })
  },
  methods: {
    stepResult () {
      const value = {}
      this.$_.set(value, this.step.field, this.inputLocations)
      this.$emit('stepResult', {
        canGoNext: this.inputLocations.length > 0 || this.$_.get(this.step, 'optional', false),
        value
      })
    },
    async handleAddress ({ deleting = false }) {
      try {
        if (this.loading) return
        this.loading = true
        let locations = this.$_.clone(this.inputLocations)
        if (deleting) {
          locations = locations.filter((add) => add !== this.selectedAddress)
        } else if (this.editMode) {
          const indexOf = locations.findIndex(({ id }) => id === this.editMode)
          locations[indexOf] = this.selectedAddress
        } else locations.push(this.selectedAddress)
        this.inputLocations = locations
        this.stepResult()
        this.showDialog = false
        this.selectedAddress = null
      } catch (e) {
        this.useLogger(e)
      } finally {
        this.loading = false
        this.editMode = false
      }
    },
    async deleteAddress (address) {
      try {
        this.$q.dialog({
          title: this.$t({ id: 'pages.addresses.dial_title' }),
          message: this.$t({ id: 'pages.addresses.dial_message' }, { name: address.name }),
          ok: { label: this.$t({ id: 'prompt.delete_button' }) }
        }).onOk(async () => {
          this.selectedAddress = address
          await this.handleAddress({ deleting: true })
        })
      } catch (e) { this.useLogger(e) }
    }
  }
}
</script>
