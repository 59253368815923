<template>
  <div v-if="entity && cptAts">
    <img
      :src="cdnImg(cptAts.image)"
      style="height:1.5rem;"
    >
    <ActionTooltip
      :str="`Offre en provenance de ${cptAts.label}`"
    />
  </div>
</template>

<script>
import { useIntegrations } from 'hc-core/composables/integrations.js'

export default {
  props: {
    entity: {
      type: Object,
      required: true,
    }
  },
  setup () {
    const { integrations } = useIntegrations()
    return { integrations }
  },
  computed: {
    cptAts () {
      let provider = false
      for (const integration of this.integrations) {
        if (this.$_.get(this.entity, `platformData._integrations.${integration.key}`, false)) {
          provider = integration
        }
      }
      if (!provider) return false
      else return provider
    }
  }
}
</script>
