import { sanitizeFilename } from 'hc-core/composables/misc.js'

export default {
  data () {
    return {
      // Campaigns
      campaignsTableActions: [
        {
          label: this.$t({ id: 'prompt.open_button' }),
          icon: 'uil:pen',
          fn: (i) => {
            this.$router.push({ name: 'questionnaire', params: { id: i.id } })
          }
        },
        {
          icon: 'uil:trash',
          color: 'negative',
          label: this.$t({ id: 'prompt.delete_button' }),
          fn: (i) => {
            try {
              this.$q.dialog({
                title: this.$t({ id: 'prompt.delete_button' }),
                message: this.$t({ id: 'communication.campaigns.delete_subtitle' }, { label: i.data.label }),
                html: true,
                cancel: true
              }).onOk(async () => {
                const linkedResponses = await this.$store.dispatch('document/list', { type: 'questionnaire', targetId: i.id })
                if (linkedResponses.nbResults > 0) {
                  this.notifyError('communication.campaigns.delete_notification_canceled')
                } else {
                  await this.$store.dispatch('document/remove', { id: i.id })
                  this.notifySuccess('communication.campaigns.delete_notification')
                }
              })
            } catch (e) { this.useLogger(e) }
          }
        }
      ],

      // Collaborators
      collaboratorsTableActions: [
        {
          icon: 'uil:save',
          color: 'positive',
          label: this.$t({ id: 'prompt.save_button' }),
          fn: (i) => { this.updateOrg() }
        },
        {
          icon: 'uil:trash',
          color: 'negative',
          label: this.$t({ id: 'prompt.delete_button' }),
          fn: (i) => {
            this.collaborators = this.$_.xor(this.collaborators, [i])
            this.updateOrg()
          }
        }
      ],

      // Questionnaire
      questIcons: {
        text: 'uil:text',
        textarea: 'uil:text-fields',
        select: 'uil:list-ul',
        radio: 'uil:check-circle',
        checkbox: 'uil:check-square',
        rating: 'uil:star',
        slider: 'uil:slider-h',
        range: 'uil:slider-h-range',
      },
      questLabels: [
        {
          value: 'questionnaireForm',
          label: this.$t({ id: 'communication.forms.types.questionnaireForm' }),
          type: 'form',
          relatedType: 'questionnaireResponse',
          color: 'purple-3',
          icon: 'uil:file-check',
          conditions: [
            { key: 'label', value: 'questionnaireForm' },
          ]
        },
        {
          value: 'questionnaireResponse',
          label: this.$t({ id: 'communication.forms.types.questionnaireResponse' }),
          type: 'response',
          relatedType: 'questionnaireForm',
          color: 'purple-3',
          icon: 'uil:file-check',
          conditions: [
            { key: 'label', value: 'questionnaireResponse' },
          ]
        },
        {
          value: 'pollForm',
          label: this.$t({ id: 'communication.forms.types.pollForm' }),
          type: 'form',
          relatedType: 'pollResponse',
          color: 'gold-3',
          icon: 'uil:file-check',
          conditions: [
            { key: 'label', value: 'pollForm' },
          ]
        },
        {
          value: 'pollResponse',
          label: this.$t({ id: 'communication.forms.types.pollResponse' }),
          type: 'response',
          relatedType: 'pollForm',
          color: 'gold-3',
          icon: 'uil:file-check',
          conditions: [
            { key: 'label', value: 'pollResponse' },
          ]
        },
        {
          value: 'createEventForm',
          label: this.$t({ id: 'communication.forms.types.createEventForm' }),
          description: this.$t({ id: 'communication.forms.types.createEventFormDescription' }),
          type: 'form',
          relatedType: 'createEventResponse',
          color: 'gold-2',
          icon: 'uil:file-check',
          conditions: [
            { key: 'label', value: 'createEventForm' },
          ]
        },
        {
          value: 'createEventResponse',
          label: this.$t({ id: 'communication.forms.types.createEventResponse' }),
          type: 'response',
          relatedType: 'createEventForm',
          color: 'gold-2',
          icon: 'uil:file-check',
          conditions: [
            { key: 'label', value: 'createEventResponse' },
          ]
        },
        {
          value: 'oneToOneForm',
          label: this.$t({ id: 'communication.forms.types.oneToOneForm' }),
          type: 'form',
          relatedType: 'oneToOneResponse',
          color: 'blue-3',
          icon: 'uil:file-check',
          conditions: [
            { key: 'label', value: 'oneToOneForm' },
          ]
        },
        {
          value: 'oneToOneResponse',
          label: this.$t({ id: 'communication.forms.types.oneToOneResponse' }),
          type: 'response',
          relatedType: 'oneToOneForm',
          color: 'blue-3',
          icon: 'uil:file-check',
          openInMeeting: true,
          conditions: [
            { key: 'label', value: 'oneToOneResponse' },
          ]
        },
        {
          value: 'proMeetingForm',
          label: this.$t({ id: 'communication.forms.types.proMeetingForm' }),
          type: 'form',
          relatedType: 'proMeetingResponse',
          color: 'green-3',
          icon: 'uil:file-check',
          conditions: [
            { key: 'label', value: 'proMeetingForm' },
          ]
        },
        {
          value: 'proMeetingResponse',
          label: this.$t({ id: 'communication.forms.types.proMeetingResponse' }),
          type: 'response',
          relatedType: 'proMeetingForm',
          color: 'green-3',
          icon: 'uil:file-check',
          openInMeeting: true,
          conditions: [
            { key: 'label', value: 'proMeetingResponse' },
          ]
        },
        {
          value: 'feedbackForm',
          label: this.$t({ id: 'communication.forms.types.feedbackForm' }),
          type: 'form',
          relatedType: 'feedbackResponse',
          color: 'red-2',
          icon: 'uil:file-check',
          conditions: [
            { key: 'label', value: 'feedbackForm' },
          ]
        },
        {
          value: 'feedbackResponse',
          label: this.$t({ id: 'communication.forms.types.feedbackResponse' }),
          type: 'response',
          relatedType: 'feedbackForm',
          color: 'red-2',
          icon: 'uil:file-check',
          conditions: [
            { key: 'label', value: 'feedbackResponse' },
          ]
        },
        // {
        //   value: 'testimonialForm',
        //   label: this.$t({ id: 'communication.forms.types.testimonialForm' }),
        //   description: this.$t({ id: 'communication.forms.types.testimonialFormDescription' }),
        //   type: 'form',
        //   relatedType: 'testimonialResponse',
        //   color: 'red-3',
        //   icon: 'uil:file-check',
        //   conditions: [
        //     { key: 'label', value: 'testimonialForm' },
        //   ]
        // },
        // {
        //   value: 'testimonialResponse',
        //   label: this.$t({ id: 'communication.forms.types.testimonialResponse' }),
        //   type: 'response',
        //   relatedType: 'testimonialForm',
        //   color: 'red-3',
        //   icon: 'uil:file-check',
        //   conditions: [
        //     { key: 'label', value: 'testimonialResponse' },
        //   ]
        // },
      ],
      questTableActions: [
        {
          tooltip: 'Ouvrir',
          label: 'Ouvrir',
          icon: 'uil:pen',
          fn: (i) => {
            this.$router.push({ name: 'questionnaire', params: { id: i.id } })
          }
        },
        {
          icon: 'uil:trash',
          color: 'negative',
          label: this.$t({ id: 'prompt.delete_button' }),
          fn: (i) => {
            try {
              this.$q.dialog({
                title: this.$t({ id: 'prompt.delete_button' }),
                message: this.$t({ id: 'communication.forms.delete_subtitle' }, { label: i.data.label }),
                html: true,
                cancel: true
              }).onOk(async () => {
                await this.$store.dispatch('document/remove', { id: i.id })
                this.notifySuccess('communication.campaigns.delete_notification')
              })
            } catch (e) { this.useLogger(e) }
          }
        }
        // {
        //   tooltip: 'Envoyer des invitations',
        //   icon: 'envelope-add',
        //   fn: (q) => {
        //     this.showQuestCreator = false
        //     this.showQuestAnalytics = false
        //     this.dataQuest = q
        //     this.inviteQuestDialog = true
        //   }
        // },
        // {
        //   tooltip: 'Statistiques',
        //   icon: 'analytics',
        //   fn: (q) => {
        //     this.showQuestCreator = false
        //     this.showQuestAnalytics = true
        //     this.dataQuest = q
        //   }
        // },
      ],
    }
  },
  computed: {
    // Campaigns
    campaignTableProp () {
      return {
        // User inputs for filtering
        columns: [
          { name: 'label', align: 'left', label: this.$t({ id: 'communication.campaigns.title_label' }) },
          { name: 'createdDate', align: 'left', label: this.$t({ id: 'communication.campaigns.creation_date' }) },
          { name: 'actions', align: 'right', label: this.$t({ id: 'prompt.actions_button' }) },
        ],
        refresh: async (params) => {
          return await this.$store.dispatch('document/list', { authorId: this.currentUser.id, type: 'questionnaire', metadata: { isCampaign: true }, ...params })
        },
      }
    },

    // Collaborators
    collaboratorsList () {
      return this.$uElements('implantations').map((imp) => {
        return {
          label: this.pickFirstKey(imp, ['company.name', 'imp.displayName'], 'Implantation'),
          company: imp.company,
          collaborators: this.$_.get(imp, 'metadata._communication.collaborators', [])
        }
      })
    },

    // Questionnaire
    tableProp () {
      return {
        // User inputs for filtering
        filters: {
          selects: [
            {
              key: 'label',
              label: this.$t({ id: 'questionnaire.type' }),
              options: this.$_.concat(
                [
                  { label: 'Tous les types', value: this.questLabels.filter(ql => ql.type === 'form').map(ql => ql.value), default: true }
                ],
                this.questLabels.filter(ql => ql.type === 'form')
              )
            }
          ],
        },
        columns: [
          { name: 'label', align: 'left', label: this.$t({ id: 'questionnaire.title' }) },
          { name: 'type', align: 'left', label: this.$t({ id: 'questionnaire.type' }) },
          { name: 'createdDate', align: 'left', label: this.$t({ id: 'communication.forms.creation_date' }) },
          { name: 'updatedDate', align: 'left', label: this.$t({ id: 'communication.forms.update_date' }) },
          { name: 'actions', align: 'right', label: this.$t({ id: 'prompt.actions_button' }) },
        ],
        refresh: async (params) => {
          return await this.$store.dispatch('document/list', { authorId: this.currentUser.id, type: 'questionnaire', metadata: { isForm: true }, ...params })
        },
      }
    }
  },
  methods: {
    // Generic
    cptChip (element) {
      return this.questLabels.find((s) => {
        let result = true
        for (const cond of s.conditions) result &= element[cond.key] === cond.value
        return result
      })
    },
    cptQuestLabel (element) {
      return this.questLabels.find((s) => {
        let result = true
        for (const cond of s.conditions) result &= element[cond.key] === cond.value
        return result
      })
    },
    cptParticipant (ele, short = true) {
      // Passing doc
      let target = ele
      let res = this.$t({ id: 'communication.participants.anonymous_label' })
      if (ele.id && ele.id.startsWith('doc_')) {
        if (this.$_.get(ele, 'metadata.isAnonymous', true)) return res
        target = this.$_.get(ele, 'metadata._private', ele)
      }
      // Passing collaborator
      if (this.pickFirstKey(target, ['firstname', 'lastname'], false)) {
        res = short ? `${this.$_.get(target, 'firstname[0]', '').toUpperCase()}${this.$_.get(target, 'lastname[0]', '').toUpperCase()}` : this.$_.upperFirst(`${this.$_.get(target, 'firstname', '')} ${this.$_.get(target, 'lastname', '')}`)
      } else {
        res = short ? this.$_.get(target, 'email[0]', '-').toUpperCase() : this.$_.get(target, 'email', '-')
      }

      return {
        label: res,
        email: this.$_.get(target, 'email', ''),
        job: this.$_.get(target, 'job', '')
      }
    },

    // Campaigns
    async sendCampaign ({ campaign }) {
      const participants = this.$_.get(campaign, 'metadata._communication.participantList', [])
      if (!campaign || !participants.length) return
      try {
        await this.$q.dialog({
          title: this.$t({ id: 'communication.campaigns.send_dialog_title' }),
          message: this.$t({ id: 'communication.campaigns.send_dialog_subtitle' }, { label: campaign.data.label, nb: participants.length }),
          html: true,
          cancel: true
        }).onOk(async () => {
          this.loading = true

          const authorId = this.$_.get(this.currentUser, 'id', this.$_.get(this.$store.getters['auth/currentUser'], 'id', this.$_.get(campaign, 'authorId', undefined)))
          const response = {
            targetId: campaign.id,
            authorId,
            type: 'questionnaire',
            label: this.$_.get(this.questLabels.find(q => q.value === campaign.label), 'relatedType', 'questionnaireResponse'),
            data: {
              ...this.$_.pick(campaign.data, ['blocks', 'label', 'context', 'finishedMessage'])
            },
            metadata: {
              isAnonymous: this.$_.get(campaign, 'metadata.isAnonymous', true),
              isGeneric: this.$_.get(campaign, 'metadata.isGeneric', undefined),
              sender: this.$_.get(campaign, 'metadata.sender', null),
              _communication: {
                sentDate: new Date().toISOString(),
                expirationDate: this.$_.get(campaign, 'metadata._communication.expiracyDate', undefined),
              }
            }
          }
          const collabArray = this.$_.flatten(this.collaboratorsList.map(list => list.collaborators))

          for (const email of participants) {
            // Add firstname / lastname / job if present in organizations
            this.$_.set(response, 'metadata._private', collabArray.find(c => c.email === email) ?? { email })

            const responseObject = await this.$store.dispatch('document/create', response)

            await this.$store.dispatch('auth/createToken', {
              userId: authorId,
              type: 'questionnaire',
              expirationDate: this.$_.get(campaign, 'metadata._communication.expiracyDate', undefined),
              data: {
                responseId: responseObject.id,
                email,
              }
            })
          }

          // Update campaign to lock it from edit
          const updatedCampaign = await this.$store.dispatch('document/update', {
            id: campaign.id,
            attrs: {
              metadata: {
                _communication: {
                  sent: true,
                  sentDate: new Date().toISOString()
                }
              }
            }
          })

          this.loading = false
          this.sentCampaignDialog = true
          this.notifySuccess('communication.campaigns.sent')
          if (this.fetchCampaign) await this.fetchCampaign()
          return updatedCampaign
        })
      } catch (e) { this.useLogger(e) }
    },
    async exportCampaignAsCsv ({ campaign = undefined, responses = undefined }) {
      if (!campaign || !responses) return
      try {
        this.loading = true
        const blocksLabels = this.$_.get(campaign, 'data.blocks', []).map(b => b.label)
        const header = this.$_.concat(
          [this.$t({ id: 'communication.participants.participant_label' }), sanitizeFilename(this.$t({ id: 'communication.analytics.response_sent' })), sanitizeFilename(this.$t({ id: 'communication.analytics.response_submit' }))],
          blocksLabels.map(_ => JSON.stringify(_))
        ).join(';') + '\n'
        const res = responses.reduce((acc, response) => {
          return acc + (
            [
              this.cptParticipant(response, false).label,
              new Date(this.$_.get(response, 'metadata._communication.sentDate', '/')).toLocaleString(),
              new Date(this.$_.get(response, 'data.submittedAt', '/')).toLocaleString(),
              ...blocksLabels.map((bl) => { return this.$_.get(this.$_.get(response, 'data.blocks', []).find(b => b.label === bl), 'value', '/') })
            ].map(_ => JSON.stringify(_)).join(';') + '\n'
          )
        }, header)

        const link = document.createElement('a')
        link.setAttribute('href', encodeURI(`data:text/csv;charset=utf-8,${res}`))
        link.setAttribute('download', sanitizeFilename(`${this.$_.get(campaign, 'data.label', 'Campagne entretiens')}_HappyCab.csv`))
        // document.body.appendChild(link) // Required for FF
        link.click()
        return null
      } catch (e) {
        this.useLogger(e)
      } finally {
        this.loading = false
      }
    },

    // Collaborators

    // Questionnaire
    async refresh (params = {}) {
      try {
        // getUserOrganizations({ fields: 'questionnaire' })
        return await this.$store.dispatch('document/list', {
          ...params,
          type: 'questionnaire',
          authorId: this.$_.get(params, 'authorId', ['platform', this.currentUser.id])
        })
      } catch (e) { this.useLogger(e) }
    },
  }
}
