<template>
  <div>
    <div id="EmojiPickerElement" ref="EmojiPickerElement" />
    <QBtn flat round size="22px" class="q-mr-sm" :label="hexToEmo(emoji)" @click="setupEventListener">
      <ActionTooltip path="pages.advantages.choose_emoji" />
      <QPopupProxy>
        <emoji-picker
          class="light"
          locale="fr"
          @emoji-click="onEmojiSelect"
        />
      </QPopupProxy>
    </QBtn>
  </div>
</template>

<script>
import { hexToEmo, emoToHex } from 'hc-core/composables/misc.js'

export default {
  props: {
    formObject: {
      type: Object,
      default: null,
    },
    step: {
      type: Object,
      default: null,
    },
    readonly: {
      type: Boolean,
      default: false,
    },
  },
  emits: ['stepResult'],
  data () {
    return {
      emoji: '1f381'
    }
  },
  created () {
    this.$nextTick(() => {
      if (!this.$_.get(this.formObject, this.step.field) && this.$_.get(this.step, 'copyFromObject')) {
        this.emoji = this.$_.cloneDeep(this.$_.get(this.step, `copyFromObject.${this.step.field}`, []))
      } else this.emoji = this.$_.cloneDeep(this.$_.get(this.formObject, this.step.field, []))
      this.stepResult()
    })
  },
  mounted () {
    if (process.env.CLIENT) {
      this.$nextTick(() => {
        const scriptElement = document.createElement('script')
        scriptElement.setAttribute('src', 'https://cdn.jsdelivr.net/npm/emoji-picker-element@^1/index.js')
        scriptElement.setAttribute('type', 'module')
        document.getElementById('EmojiPickerElement').appendChild(scriptElement)
      })
    }
  },
  methods: {
    hexToEmo,
    onEmojiSelect (event) { this.emoji = emoToHex(this.$_.get(event, 'detail.unicode', '🎁')); this.stepResult() },
    stepResult () {
      const value = {}
      this.$_.set(value, this.step.field, Array.isArray(this.emoji) ? '1f381' : this.emoji)
      this.$emit('stepResult', {
        canGoNext: this.emoji !== undefined || this.$_.get(this.step, 'optional', false),
        value
      })
    },
  }
}
</script>
