<template>
  <QSelect
    v-model="inputSelect"
    :readonly="readonly"
    :label="computedLabel"
    :options="step.options"
    bg-color="white"
    outlined
    emit-value
    map-options
    @update:model-value="stepResult"
  />
</template>

<script>
export default {
  props: {
    formObject: {
      type: Object,
      default: null,
    },
    step: {
      type: Object,
      default: null,
    },
    readonly: {
      type: Boolean,
      default: false,
    },
  },
  emits: ['stepResult'],
  data () {
    return {
      inputSelect: false,
    }
  },
  computed: {
    // TODO : consider always passing trn strings, so directly use $t in :label
    computedLabel () {
      const l = this.$_.get(this.step, 'label', false)
      return l ? this.translationExists(l) ? this.$t({ id: l }) : l : false
    },
  },
  created () {
    this.$nextTick(() => {
      if (!this.$_.get(this.formObject, this.step.field) && this.$_.get(this.step, 'copyFromObject')) {
        this.inputSelect = this.$_.get(this.step, `copyFromObject.${this.step.field}`)
      } else this.inputSelect = this.$_.get(this.formObject, this.step.field)
      this.stepResult()
    })
  },
  methods: {
    stepResult () {
      const value = {}
      this.$_.set(value, this.step.field, this.inputSelect)
      this.$emit('stepResult', {
        canGoNext: this.inputSelect !== null || this.$_.get(this.step, 'optional', false),
        value
      })
    }
  }
}
</script>
