<template>
  <QInput ref="InputField" v-model="stringInput" :readonly="readonly" :mask="step.format.replace(/\w/g, '#')" :label="step.label" bg-color="white" outlined fill-mask @update:model-value="stepResult">
    <template #append>
      <QIcon name="uil:calender" class="cursor-pointer">
        <QPopupProxy ref="popupPicker" transition-show="scale" transition-hide="scale">
          <QDate v-model="inputDate" :readonly="readonly" :navigation-min-year-month="`${new Date().getFullYear()-100}/${new Date().getMonth()+1}`" :navigation-max-year-month="`${new Date().getFullYear()}/${new Date().getMonth()+1}`" minimal :mask="step.format" emit-immediately default-view="Years" @update:model-value="checkDate">
            <div class="row items-center justify-center">
              <QBtn v-close-popup label="Fermer" no-caps size="md" color="primary" flat />
            </div>
          </QDate>
        </QPopupProxy>
      </QIcon>
    </template>
  </QInput>
</template>

<script>
export default {
  props: {
    formObject: {
      type: Object,
      default: null,
    },
    step: {
      type: Object,
      default: null,
    },
    readonly: {
      type: Boolean,
      default: false,
    },
  },
  emits: ['stepResult'],
  data () {
    return {
      inputDate: null,
      stringInput: null,
    }
  },
  created () {
    this.$nextTick(() => {
      if (!this.$_.get(this.formObject, this.step.field) && this.$_.get(this.step, 'copyFromObject')) {
        this.stringInput = this.$_.get(this.step, `copyFromObject.${this.step.field}`)
      } else this.stringInput = this.$_.get(this.formObject, this.step.field, undefined)
      this.stepResult()
    })
  },
  mounted () {
    this.$refs.InputField.focus()
  },
  methods: {
    stepResult () {
      const value = {}
      this.$_.set(value, this.step.field, this.stringInput)
      let checkStatus = true // Prevent emits of __/____ values even if step is optionnal
      if (this.$_.get(this.step, 'checkInputIfFilled', false) && this.$_.get(this, 'stringInput', '').match('_')) checkStatus = false
      this.$emit('stepResult', {
        canGoNext: (this.$_.get(this.stringInput, 'length', 0) > 0 && checkStatus) || (this.$_.get(this.step, 'optional', false) && checkStatus),
        value: value,
      })
    },
    checkDate (val, reason, details) {
      if (this.$refs.popupPicker) {
        if ((this.step.format === 'YYYY' && reason === 'year') || (this.step.format === 'MM/YYYY' && reason === 'month')) {
          this.$refs.popupPicker.hide()
          this.stringInput = val
          this.stepResult()
        }
      }
    },
  },
}
</script>
